.App {
  box-sizing: border-box;
}

header {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 7vh 0px;
  align-content: stretch;
  padding: 24vh 10vw;
}

header .header-profile {
  display: flex;
  flex-direction: column;
  gap: 7vh 0;
  width: 100%;
}

header .profile-thumb {
  width: 15vw;
  height: auto;
  border-radius: 100%;
  filter: grayscale(100%);
}

header h1 {
  width: 100%;
  color: #9E9DA7;
  font-size: 30px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 300;
  font-style: normal;
  text-transform: uppercase;
  /* letter-spacing: -3px; */
}
header h1 span {
  /* font-weight: 700; */
  color: #33323B;
  /* display: block; */
}
header hr {
  display: block;
  border: 0;
  margin: 0;
  width: 10vw;
  height: 1px;
  background: #9E9DA7;
}
header ul {
  width: 100%;
}
header ul li {
  font-family: "Baskervville", serif;
  font-weight: 400;
  font-style: italic;
  font-size: 40px;
}
header ul li:nth-child(1) {
  color: #85848C;
}
header ul li:nth-child(1) span {
  color: #6D6C73;
}
header ul li:nth-child(2) {
  color: #9D9CA6;
}
header ul li:nth-child(3) {
  color: #B5B4BF;
}
header ul li:nth-child(4) {
  color: #CDCCD9;
}


.section {
  padding: 0 10vw 20vh 10vw;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 4vh 0px;
  align-content: stretch;
}

.section .column {
  width: 100%;
}

.section .column .block-content {
  margin: 0 0 7vh;
}

.section .column h2 {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 21px;
  text-transform: uppercase;
  line-height: 7vh;
  margin-bottom: 7vh;
  color: #33323B;
}
.section .column h3 {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 60px;
  text-transform: uppercase;
  line-height: 1.1;
  margin-bottom: 5vh;
  letter-spacing: -3px;
}
.section .column h3 span {
  font-weight: 400;
  font-size: 52px;
}

/* color-variants */
.section .column.color-1 h2 {
  color: #F3B04E;
  border-color: #F3B04E;
}
.section .column.color-1 h3 {
  color: #F3B04E;
}
.section .column.color-2 h2 {
  color: #D07242;
  border-color: #D07242;
}
.section .column.color-2 h3 {
  color: #D07242;
}
.section .column.color-3 h2 {
  color: #8B88A1;
  border-color: #8B88A1;
}
.section .column.color-3 h3 {
  color: #8B88A1;
}

.section .column h4 {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 1.4;
  margin-bottom: 3vh;
}
.section .column h4 span {
  font-weight: 700;
}
.section .column p {
  font-family: "Baskervville", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 1.4;
  margin-bottom: 3vh;
}

.section .column ul {
  font-family: "Baskervville", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 1.4;
  margin-bottom: 3vh;
}
.section .column ul li {
  /* list-style: square; */
  padding: 0 0 0.8em 1em;
  position: relative;
}
.section .column ul li::before {
  content: " ";
  width: 0.3em;
  height: 0.3em;
  background-color: #000;
  margin: 0;
  float: left;
  position: absolute;
  display: block;
  left: 0;
  top: 0.6em;
}


/* tablet */
@media only screen and (min-width: 768px) {
  header {
    /* gap: 0px 10vh; */
    padding: 15vh 10vw;
  }
}

/* desktop */
@media only screen and (min-width: 769px) {
  header {
    padding: 30vh 10vw;
    flex-direction: row;
    gap: 0px 10vh;
    align-content: center;
    align-items: center;
    background: #fff;
  }
  header .header-profile {
  }
  header .profile-thumb {
    width: 5vw;
    border: 1px solid #CDCCD9;
  }

  header hr {
    display: none;
  }

  .section {
    padding: 0 10vw 10vh 10vw;
    text-align: left;
    display: flex;
    flex-direction: row;
    gap: 0 7vh;
    align-content: stretch;
  }
  .section .column h2 {
    border-top: 1px solid #33323B;
  }
}
